<template lang="pug">

  .vw-form-part(
    :id = "`${settings.nameFieldOnJSON}Element`"
    :class = "[ `part-${settings.nameFieldOnJSON}`, computedErrors ? 'part-error': '' ]")

    .vw-form-title(
      v-html = "translates[settings.translateKeyLabel][configs.language]")

    .vw-form-part-item.item-checkbox(
      v-for = "(item, i) in request.typeInquiry"
      :key = "i")

      label.vw-form-radio
        input.vw-form-radio-input(
          v-model.trim = "request[settings.nameFieldOnJSON]"
          :value = "item.name"
          type = "radio"
          @keydown = "changeInputFromKeyboard($event, 'item', item, i, request[settings.nameFieldOnJSON])"
          @change = "changeElement()"
          :ref = "`r_${settings.nameFieldOnJSON}_` + item.name")

        span.vw-form-radio-icon(
          :id = "`${settings.nameFieldOnJSON}_` + (i + 1)")
          icon-checked

        span.vw-form-radio-text(
          v-html = "translates.formTypeInquiry[item.name][configs.language]")

    .vw-form-error(
      v-if = "computedErrors !== true && computedErrors !== false && configs.showErrors") {{ computedErrors }}

</template>

<script>
import { mapState, mapGetters } from 'vuex'
import iconChecked from './../assets/icons/iconChecked'

export default {
  props: [ 'focus', 'orientation', 'settings' ],

  components: {
    iconChecked
  },

  computed: {
    ...mapState({
      configs: state => state.configs,
      request: state => state.request,
      translates: state => state.translates
    }),
    ...mapGetters([
      'getError'
    ]),
    computedErrors () {
      let errors = false
      if (this.settings.namesErrors && this.settings.namesErrors.length) {
        this.settings.namesErrors.forEach((x) => {
          if (this.getError(x) && this.getError(x)[0]) {
            if (this.getError(x)[0] === 'all') {
              errors = true
            } else {
              errors = this.getError(x)[0]
            }
          }
        })
      }
      return errors
    }
  },

  watch: {
    focus (val) {
      if (val === 'wheelchair') {
        if (this.orientation === 'down') {
          this.$refs['r_wheelchair_' + this.request.wheelchair[this.request.wheelchair.length - 1].name][0].focus()
        } else {
          this.$refs['r_wheelchair_' + this.request.wheelchair[0].name][0].focus()
        }
      }
    }
  },

  methods: {

    changeElement () {
      this.$emit('changeElement', this.settings.nameFieldOnJSON)
    },

    changeInputFromKeyboard () {
      // if (e && [ 'Enter', ' ', 'ArrowUp', 'ArrowDown', 'Tab' ].includes(e.key)) {
      //   e.preventDefault()
      //
      //   if ([ 'other', 'weight' ].includes(type)) {
      //     if (e.key == 'Enter') {
      //       if (type === 'weight') {
      //         this.$emit('getFocusElement', [ 'typeInquiry', 'next' ])
      //       } else {
      //         this.$refs['r_wheelchair_other_' + item.other[iOther].name][0].click()
      //         if (item.other[iOther + 1]) {
      //           this.$refs['r_wheelchair_other_' + item.other[iOther + 1].name][0].focus()
      //         } else {
      //           this.$refs.r_wheelchair_other_weight[0].focus()
      //         }
      //       }
      //     }
      //
      //     if ([ 'ArrowUp' ].includes(e.key)) {
      //       if (type === 'weight') {
      //         this.$refs['r_wheelchair_other_' + item.other[item.other.length - 1].name][0].focus()
      //       } else {
      //         if (item.other[iOther - 1]) {
      //           this.$refs['r_wheelchair_other_' + item.other[iOther - 1].name][0].focus()
      //         } else {
      //           this.$refs['r_wheelchair_' + item.name][0].focus()
      //         }
      //       }
      //     }
      //
      //     if ([ 'ArrowDown', 'Tab' ].includes(e.key)) {
      //       if (type === 'weight') {
      //         if (iItem + 1 <= arrayItems.length - 1) {
      //           this.$refs['r_wheelchair_' + arrayItems[iItem + 1].name][0].focus()
      //         } else {
      //           this.$emit('getFocusElement', [ 'typeInquiry', 'next' ])
      //         }
      //       } else {
      //         if (item.other[iOther + 1]) {
      //           this.$refs['r_wheelchair_other_' + item.other[iOther + 1].name][0].focus()
      //         } else {
      //           this.$refs.r_wheelchair_other_weight[0].focus()
      //         }
      //       }
      //     }
      //   } else {
      //     if (e.key == ' ') {
      //       this.$refs['r_wheelchair_' + arrayItems[iItem].name][0].click()
      //     }
      //
      //     if (e.key == 'Enter') {
      //       if (item.other) {
      //         this.$refs['r_wheelchair_' + item.name][0].click()
      //
      //         this.$nextTick(() => {
      //           if (this.$refs['r_wheelchair_other_' + item.other[0].name]) {
      //             this.$refs['r_wheelchair_other_' + item.other[0].name][0].focus()
      //           }
      //         })
      //       } else {
      //         if (iItem + 1 <= arrayItems.length - 1) {
      //           this.$refs['r_wheelchair_' + arrayItems[iItem].name][0].click()
      //           this.$refs['r_wheelchair_' + arrayItems[iItem + 1].name][0].focus()
      //         } else {
      //           this.$refs['r_wheelchair_' + arrayItems[iItem].name][0].click()
      //           this.$emit('getFocusElement', [ 'typeInquiry', 'next' ])
      //         }
      //       }
      //     }
      //
      //     if ([ 'ArrowUp' ].includes(e.key)) {
      //       if (iItem - 1 >= 0) {
      //         if (arrayItems[iItem - 1].name === this.request.type && arrayItems[iItem - 1].other) {
      //           this.$refs['r_wheelchair_other_weight'][0].focus()
      //         } else {
      //           this.$refs['r_wheelchair_' + arrayItems[iItem - 1].name][0].focus()
      //         }
      //       } else {
      //         this.$emit('getFocusElement', [ 'typeInquiry', 'prev' ])
      //       }
      //     }
      //
      //     if ([ 'ArrowDown', 'Tab' ].includes(e.key)) {
      //       if (iItem + 1 <= arrayItems.length - 1) {
      //         if (item.other && item.name === this.request.type) {
      //           this.$refs['r_wheelchair_other_' + item.other[0].name][0].focus()
      //         } else {
      //           this.$refs['r_wheelchair_' + arrayItems[iItem + 1].name][0].focus()
      //         }
      //       } else {
      //         this.$emit('getFocusElement', [ 'typeInquiry', 'next' ])
      //       }
      //     }
      //   }
      //
      // }
    }

  }
}
</script>

<style scoped lang="sass">

</style>
