<template lang="pug">

  .vw-form-flight
    .vw-form-flight-icon(
      @click = "toggleFlights()")
      iconTudaSuda

</template>

<script>
import { mapState, mapGetters } from 'vuex'
import iconTudaSuda from './../assets/icons/iconTudaSuda'

export default {
  props: [ 'focus', 'orientation', 'settings' ],

  components: {
    iconTudaSuda
  },

  computed: {
    ...mapState({
      configs: state => state.configs,
      request: state => state.request,
      translates: state => state.translates
    }),
    ...mapGetters([
      'getError'
    ]),
    computedErrors () {
      let errors = false
      if (this.settings.namesErrors && this.settings.namesErrors.length) {
        this.settings.namesErrors.forEach((x) => {
          if (this.getError(x) && this.getError(x)[0]) {
            if (this.getError(x)[0] === 'all') {
              errors = true
            } else {
              errors = this.getError(x)[0]
            }
          }
        })
      }
      return errors
    }
  },

  watch: {
    focus (val) {
      if ([ 'user', 'firstname' ].includes(val)) {
        this.$refs.firstname.focus()
      }
      if ([ 'middlename' ].includes(val)) {
        this.$refs.firstname.focus()
      }
    }
  },

  methods: {

    toggleFlights () {
      this.changeElement(this.request.flightType === 'departure' ? 'arrival' : 'departure')
    },

    changeElementAnalytics () {
      if (this.settings.analyticsChangeEvent) {
        this.$emit('analytics', this.settings.analyticsChangeEvent)
      }
    },

    changeElement (type) {
      this.request[this.settings.nameFieldOnJSON] = type
      this.$emit('changeElement', this.settings.nameFieldOnJSON)
      this.changeElementAnalytics()
    },

    changeInputFromKeyboard () {
      // if (e && [ 'Enter', 'ArrowDown', 'ArrowUp', 'Tab' ].includes(e.key)) {
      //   e.preventDefault()
      //
      //   if ([ 'Enter', 'ArrowDown', 'Tab' ].includes(e.key)) {
      //     if (type === 'firstname') {
      //       this.$emit('getFocusElement', [ 'user', 'next' ])
      //     }
      //   }
      //
      //   if ([ 'ArrowUp' ].includes(e.key)) {
      //     if (type === 'firstname') {
      //       this.$emit('getFocusElement', [ 'user', 'prev' ])
      //     }
      //   }
      // }
    }

  }
}
</script>

<style lang="sass">

.vw-form-flight
  &-icon
    width: 2rem
    height: 2rem
    cursor: pointer
    margin-bottom: calc((3.5714rem - 2.1428rem) / 2)

    svg
      width: 100%
      height: 100%

      path
        fill: var(--c-button)
        transition: .3s

    &:hover
      svg
        path
          fill: var(--c-button-hover)

</style>
