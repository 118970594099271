<template lang="pug">

  .vw-form-part(
    :id = "`${settings.nameFieldOnJSON}Element`"
    :class = "[ `part-${settings.nameFieldOnJSON}`, computedErrors ? 'part-error': '' ]")

    .vw-form-group-part.has-label
      textarea(name="`vw-form-${settings.nameFieldOnJSON}`" type="text" class="input-hidden")
      textarea.vw-form-textarea.input-comment(
        v-model.trim = "request.comment"
        @keydown = "changeInputFromKeyboard($event, 'comment')"
        @input = "changeElement()"
        @change = "changeElementAnalytics()"
        :name = "`vw-form-${settings.nameFieldOnJSON}`"
        :ref = "settings.nameFieldOnJSON")

      .vw-form-label(
        v-html = "translates.formComment[configs.language]")

</template>

<script>
import {mapGetters, mapState} from 'vuex'

  export default {
    props: [ 'focus', 'orientation', 'settings' ],
    computed: {
      ...mapState({
        configs: state => state.configs,
        request: state => state.request,
        translates: state => state.translates
      }),
      ...mapGetters([
        'getError'
      ]),
      computedErrors () {
        let errors = false
        if (this.settings.namesErrors && this.settings.namesErrors.length) {
          this.settings.namesErrors.forEach((x) => {
            if (this.getError(x) && this.getError(x)[0]) {
              if (this.getError(x)[0] === 'all') {
                errors = true
              } else {
                errors = this.getError(x)[0]
              }
            }
          })
        }
        return errors
      }
    },

    watch: {
      focus (val) {
        if (val === 'comment') {
          this.$refs.comment.focus()
        }
      }
    },

    methods: {

      changeElementAnalytics () {
        if (this.settings.analyticsChangeEvent) {
          this.$emit('analytics', this.settings.analyticsChangeEvent)
        }
      },

      changeElement () {
        this.$emit('changeElement', this.settings.nameFieldOnJSON)
      },

      changeInputFromKeyboard () {
        // if (e && [ 'Enter', 'ArrowUp', 'ArrowDown', 'Tab' ].includes(e.key)) {
        //   e.preventDefault()
        //
        //   if ([ 'Enter', 'ArrowDown', 'Tab' ].includes(e.key)) {
        //     this.$emit('getFocusElement', [ 'comment', 'next' ])
        //   }
        //
        //   if ([ 'ArrowUp' ].includes(e.key)) {
        //     this.$emit('getFocusElement', [ 'comment', 'prev' ])
        //   }
        //
        // }
      }

    }
  }
</script>

<style scoped lang="sass">

</style>
