<template lang="pug">

  .vw-form-elements
    .vw-form-elements-item._typeTitle(
      v-if = "typeof item === 'string' && translates[item]")
      .vw-form-title(
        v-html = "translates[item][configs.language]")

    .vw-form-elements-item(
      v-if = "typeof item === 'string' && !translates[item]"
      :class = "[ '_' + item ]")

      parts(
        :part = "item"
        :focus = "focus"
        :orientation = "orientation"
        @changeElement = "changeElement"
        @focusElement = "focusElement"
        @analytics = "analytics"
      )

    .vw-form-elements-separate(
      v-if = "typeof item === 'object'")

      .vw-form-title(
        v-if = "item[0].split('||')[0] && item[0].indexOf('form') !== -1"
        v-html = "translates[item[0].split('||')[0]][configs.language]")

      .vw-form-elements-separate-note(
        v-if = "item[0].split('||')[1] && item[0].indexOf('form') !== -1"
        v-html = "translates[item[0].split('||')[1]][configs.language]")

      .vw-form-elements-separate-item(
        v-for = "(part, i) in item"
        v-if = "part.indexOf('form') === -1 && getAccessElement(part)"
        :key = "i")

        .vw-form-elements-separate-item-line(v-if = "part.split('||').length > 1")
          parts(
            v-for = "(p, n) in part.split('||')"
            :key = "n"
            :part = "p"
            :focus = "focus"
            :orientation = "orientation"
            @changeElement = "changeElement"
            @focusElement = "focusElement"
            @analytics = "analytics"
          )

        template(v-else)
          parts(
            :part = "part"
            :focus = "focus"
            :orientation = "orientation"
            @changeElement = "changeElement"
            @focusElement = "focusElement"
            @analytics = "analytics"
          )

</template>

<script>
  import { mapState } from 'vuex'

  import parts from './parts'

  export default {
    props: [ 'item', 'focus', 'orientation' ],

    components: {
      parts
    },

    computed: {
      ...mapState({
        configs: state => state.configs,
        request: state => state.request,
        translates: state => state.translates
      })
    },

    methods: {
      analytics (event) {
        this.$emit('analytics', event)
      },

      getAccessElement (part) {
        return !(part === 'flight' && this.request.type === '2')
      },

      changeElement (data) {
        this.$emit('changeElement', data)
      },

      focusElement (data) {
        this.$emit('focusElement', data)
      }
    }
  }
</script>

<style lang="sass">

.vw-form
  padding-bottom: 30px

.vw-form-elements-item
  margin-bottom: 1.0714rem
  padding-left: 1.4285rem

  &._typeTitle
    margin-bottom: 0

.vw-form-elements-separate-item-line
  display: flex
  justify-content: space-between
  align-items: flex-end
  width: 100%

  @media screen and (max-width: 500px)
    flex-wrap: wrap
    position: relative

  & > *
    flex: none
    width: calc(100% / 2 - 8px - 1rem)

    @media screen and (max-width: 500px)
      width: 100%
      margin-bottom: 1.0714rem

      &:last-child
        margin-bottom: 0

    &:nth-child(2)
      width: auto
      margin: 0 .5rem

      @media screen and (max-width: 500px)
        margin: 0
        position: absolute
        top: 5.25rem
        right: 0
        transform: rotate(90deg)
        z-index: 1

  .vw-form-input.input-name,
  .vw-form-textarea.input-name
    width: 100%

.vw-form-elements-separate
  background: var(--c-group-background)
  border-radius: .2142rem
  padding: 1.4285rem
  margin-bottom: 1.7857rem
  margin-top: 1.7857rem

  &-note
    margin-bottom: 1.0714rem

  &-item
    margin-bottom: 1.0714rem

    &:last-child
      margin-bottom: 0

</style>
