<template lang="pug">

  .vw-form-part(
    :id = "`${settings.nameFieldOnJSON}Element`"
    :class = "[ `part-${settings.nameFieldOnJSON}`, computedErrors ? 'part-error': '' ]")

    .vw-form-group.group-psg
      .vw-form-group-part.has-label
        input(:name="`vw-form-${settings.nameFieldOnJSON}`" type="text" class="input-hidden")
        input.vw-form-input.input-name(
          v-model.trim = "request[settings.nameFieldOnJSON].name"
          type = "text"
          @keydown = "changeInputFromKeyboard($event, settings.nameFieldOnJSON)"
          @input = "changeElement()"
          @change = "changeElementAnalytics()"
          :name = "`vw-form-${settings.nameFieldOnJSON}`"
          :ref = "settings.nameFieldOnJSON"
          :disabled = "autocomplete.disabled")

        .vw-form-label(
          v-if = "settings.translateKeyLabel"
          v-html = "translates[settings.translateKeyLabel][configs.language]")

        .vw-form-note(
          v-if = "settings.translateKeyNote"
          v-html = "translates[settings.translateKeyNote][configs.language]")

        .vw-form-error(
          v-if = "computedErrors !== true && computedErrors !== false && configs.showErrors") {{ computedErrors }}

        .vw-form-autocomplete(
          v-if = "autocomplete.show")
          .vw-form-autocomplete-group(
            v-for = "(group, i) in autocomplete.list"
            :key = "i")
            .vw-form-autocomplete-group-title(
              :class = "{ 'cursor': group.list.length > 0 && group.list.length <= 1 }"
              @click.stop = "selectItem(group)") {{ group.city }}
              span(v-if = "group.list.length > 0 && group.list.length <= 1 && group.city !== group.list[0]") , {{ group.list[0].name }}
            .vw-form-autocomplete-group-list(
              v-if = "group.list.length > 1")
              .vw-form-autocomplete-group-list-item(
                v-for = "(airport, n) in group.list"
                :key = "n"
                @click.stop = "selectItem(airport)") {{ airport.name }}

</template>

<script>
import { mapState, mapGetters } from 'vuex'
import axios from 'axios'

export default {
  props: [ 'focus', 'orientation', 'settings' ],

  data () {
    return {
      autocomplete: {
        disabled: false,
        show: false,
        list: []
      }
    }
  },

  computed: {
    ...mapState({
      configs: state => state.configs,
      request: state => state.request,
      translates: state => state.translates
    }),
    ...mapGetters([
      'getError',
      'getApiUrl',
      'getParseSchemeElements'
    ]),
    computedErrors () {
      let errors = false
      if (this.settings.namesErrors && this.settings.namesErrors.length) {
        this.settings.namesErrors.forEach((x) => {
          if (this.getError(x) && this.getError(x)[0]) {
            if (this.getError(x)[0] === 'all') {
              errors = true
            } else {
              errors = this.getError(x)[0]
            }
          }
        })
      }
      return errors
    }
  },

  watch: {
    focus (val) {
      if ([ 'user', 'firstname' ].includes(val)) {
        this.$refs.firstname.focus()
      }
      if ([ 'middlename' ].includes(val)) {
        this.$refs.firstname.focus()
      }
    },
    'request.flightType' () {
      this.checkAutomation()
    },
  },

  mounted () {
    this.checkAutomation()
  },

  methods: {

    checkAutomation () {
      const arrival = JSON.stringify(this.request.arrival)
      const departure = JSON.stringify(this.request.departure)
      let airport = {}
      // console.log(arrival, departure)

      this.autocomplete.disabled = this.request.flightType && this.settings.nameFieldOnJSON === this.request.flightType

      if (this.autocomplete.disabled) {
        // Краснодар
        if (this.configs.partner.id === '84c639ff-b824-3515-ae57-9e8cd3785375') {
          airport = {name: 'Пашковский', id: 90}
        }

        // Сочи
        if (this.configs.partner.id === 'e201bbbb-5d84-35df-a072-fbf87a6bda14') {
          airport = {name: 'Сочи', id: 108}
        }

        // Анапа
        if (this.configs.partner.id === '7e697689-41d8-34c1-8fc9-09c87a06c895') {
          airport = {name: 'Витязево', id: 67}
        }
      }

      // TODO убрать костыль

      if (this.request.flightType === 'departure' && this.settings.nameFieldOnJSON === 'departure') {
        this.request.departure = airport
        this.request.arrival = JSON.parse(departure)
      } else if (this.request.flightType === 'arrival' && this.settings.nameFieldOnJSON === 'arrival') {
        this.request.departure = JSON.parse(arrival)
        this.request.arrival = airport
      }
    },

    changeElementAnalytics () {
      if (this.settings.analyticsChangeEvent) {
        this.$emit('analytics', this.settings.analyticsChangeEvent)
      }
    },

    changeElement () {
      this.startSearch()
      this.$emit('changeElement', this.settings.nameFieldOnJSON)
    },

    changeInputFromKeyboard () {
      // if (e && [ 'Enter', 'ArrowDown', 'ArrowUp', 'Tab' ].includes(e.key)) {
      //   e.preventDefault()
      //
      //   if ([ 'Enter', 'Tab' ].includes(e.key)) {
      //     this.$emit('focusElement', ['user', 'next'])
      //   }
      //
      //   if ([ 'ArrowDown' ].includes(e.key)) {
      //     if (type === 'firstname') {
      //       this.$emit('getFocusElement', [ 'user', 'prev' ])
      //     }
      //   }
      //
      //   if ([ 'ArrowUp' ].includes(e.key)) {
      //     if (type === 'firstname') {
      //       this.$emit('getFocusElement', [ 'user', 'prev' ])
      //     }
      //   }
      // }
    },

    startSearch () {
      if (this.request[this.settings.nameFieldOnJSON]) {
        let config = {
          method: 'get',
          url: `${this.getApiUrl()}/flight-inquiry/api/v1/terminals-autocomplete?query=${this.request[this.settings.nameFieldOnJSON].name}`,
          headers: {
            'Content-Type': 'application/json'
          }
        }

        axios(config)
          .then((res) => {
            const array = res.data
            this.parseResponse(array)
          })
      } else {
        this.parseResponse([])
      }
    },

    parseResponse (array) {
      if (array.length) {
        let list = []
        array.forEach((x) => {
          const city = list.findIndex((n) => { return n.city === x.cityName })
          if (city === -1) {
            const group = {
              city: x.cityName,
              info: x,
              list: [{ name: x.airportName, id: x.airportId }]
            }
            list.push(group)
          } else {
            const finded = list[city].list.find((n) => { return n.name === x.airportName })
            if (!finded) {
              list[city].list.push({ name: x.airportName, id: x.airportId })
            }
          }
        })
        this.autocomplete.show = true
        this.autocomplete.list = list
      } else {
        this.autocomplete.show = false
        this.autocomplete.list = []
      }
    },

    selectItem (item) {
      if (item.list) {
        this.request[this.settings.nameFieldOnJSON] = item.list[0]
      } else {
        this.request[this.settings.nameFieldOnJSON] = item
      }
      this.autocomplete.show = false
      this.autocomplete.list = []
    }

  }
}
</script>

<style scoped lang="sass">

</style>
