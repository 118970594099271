<template lang="pug">

  | <svg version="1.1" id="Слой_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 16 13" style="enable-background:new 0 0 16 13;" xml:space="preserve"><path d="M15.4,0.2c-0.4-0.3-0.8-0.3-1.2,0.1l0,0l-8,9.3L2.1,5.5c-0.3-0.3-0.9-0.3-1.3,0l0,0L0.3,6.1c-0.4,0.3-0.4,0.9,0,1.3l0,0 l4.8,4.8l0,0l0.5,0.5C5.8,12.9,6,13,6.3,13l0,0c0.2,0,0.5-0.1,0.6-0.3l0.5-0.5l0,0c0,0,7.9-9.8,8.3-10.3c0.4-0.5,0.4-0.9,0-1.2 C15.5,0.3,15.8,0.6,15.4,0.2z"/></svg>

</template>

<script>
  export default {
  }
</script>

<style scoped lang="sass">

</style>
