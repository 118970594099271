<template lang="pug">

  .vw-form-part(
    :id = "`${settings.nameFieldOnJSON}Element`"
    :class = "[ `part-${settings.nameFieldOnJSON}`, computedErrors ? 'part-error': '' ]")

    .vw-form-group.group-psg
      .vw-form-group-part.has-label
        input(:name="`vw-form-${settings.nameFieldOnJSON}`" type="text" class="input-hidden")
        input.vw-form-input.input-name(
          v-model.trim = "request[settings.nameFieldOnJSON]"
          type = "text"
          @keydown = "changeInputFromKeyboard($event, settings.nameFieldOnJSON)"
          @input = "changeElement()"
          @change = "changeElementAnalytics()"
          :name = "`vw-form-${settings.nameFieldOnJSON}`"
          :ref = "settings.nameFieldOnJSON")

        .vw-form-label(
          v-if = "settings.translateKeyLabel"
          v-html = "translates[settings.translateKeyLabel][configs.language]")

        .vw-form-note(
          v-if = "settings.translateKeyNote"
          v-html = "translates[settings.translateKeyNote][configs.language]")

        .vw-form-error(
          v-if = "computedErrors !== true && computedErrors !== false && configs.showErrors") {{ computedErrors }}

</template>

<script>
import { mapState, mapGetters } from 'vuex'

export default {
  props: [ 'focus', 'orientation', 'settings' ],
  computed: {
    ...mapState({
      configs: state => state.configs,
      request: state => state.request,
      translates: state => state.translates
    }),
    ...mapGetters([
      'getError'
    ]),
    computedErrors () {
      let errors = false
      if (this.settings.namesErrors && this.settings.namesErrors.length) {
        this.settings.namesErrors.forEach((x) => {
          if (this.getError(x) && this.getError(x)[0]) {
            if (this.getError(x)[0] === 'all') {
              errors = true
            } else {
              errors = this.getError(x)[0]
            }
          }
        })
      }
      return errors
    }
  },

  watch: {
    focus (val) {
      if ([ 'user', 'firstname' ].includes(val)) {
        this.$refs.firstname.focus()
      }
      if ([ 'middlename' ].includes(val)) {
        this.$refs.firstname.focus()
      }
    }
  },

  methods: {

    changeElementAnalytics () {
      if (this.settings.analyticsChangeEvent) {
        this.$emit('analytics', this.settings.analyticsChangeEvent)
      }
    },

    changeElement () {
      this.$emit('changeElement', this.settings.nameFieldOnJSON)
    },

    changeInputFromKeyboard () {
      // if (e && [ 'Enter', 'ArrowDown', 'ArrowUp', 'Tab' ].includes(e.key)) {
      //   e.preventDefault()
      //
      //   if ([ 'Enter', 'ArrowDown', 'Tab' ].includes(e.key)) {
      //     if (type === 'firstname') {
      //       this.$emit('getFocusElement', [ 'user', 'next' ])
      //     }
      //   }
      //
      //   if ([ 'ArrowUp' ].includes(e.key)) {
      //     if (type === 'firstname') {
      //       this.$emit('getFocusElement', [ 'user', 'prev' ])
      //     }
      //   }
      // }
    }

  }
}
</script>

<style scoped lang="sass">

.part-email
  .vw-form-input.input-name
    width: calc(100% / 2 - 16px)

    @media screen and (max-width: 500px)
      width: calc(100% - 1.4285rem)

</style>
