<template lang="pug">

  #app
    .global-wrapper(
      id = "startElement")

      form.vw-form(
        v-if = "orderPage === 'form'"
        @submit.prevent = "setRequest")

        .vw-header(
          v-html = "translates.formTitle[configs.language]")

        .vw-note
          .vw-note-annotation(
            v-html = "translates.formNote[configs.language]")

        elements(
          v-for = "(item, i) in configs.schemeElements"
          :key = "i"
          :item = "item"
          :focus = "focus"
          :orientation = "orientation"
          @getFocusElement = "getFocusElement"
          @focusElement = "focusElement"
          @changeElement = "changeElement"
          @analytics = "analytics")

        .vw-note
          .vw-note-annotation(
            v-html = "translates.formBottom[configs.language]")

          .vw-note-annotation(
            v-html = "translates.formFree[configs.language]")

      .vw-order(
        v-if = "orderPage === 'success'")
        .vw-success
          .vw-success-left
            | <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 492 492" xml:space="preserve" class="Done-titleIcon"><path data-v-0b0f8414="" d="M484.1,57.5L468,41.3c-5.1-5.1-11.8-7.9-19-7.9c-7.2,0-14,2.8-19,7.9L183.5,287.8L62,166.3c-5.1-5.1-11.8-7.9-19-7.9c-7.2,0-14,2.8-19,7.9L7.9,182.4c-5.1,5.1-7.9,11.8-7.9,19c0,7.2,2.8,14,7.9,19l139.7,139.7c0.2,0.3,0.4,0.6,0.7,0.8l16.1,15.9c5.1,5,11.8,7.6,19.1,7.6h0.1c7.2,0,14-2.5,19-7.6l16.1-16c0.3-0.3,0.5-0.5,0.6-0.7L484.1,95.5C494.6,85,494.6,68,484.1,57.5z" class="st3 main"></path></svg>
          .vw-success-right
            .vw-header Ваш заказ оплачен
            .vw-order-text В&nbsp;течение трёх рабочих дней мы&nbsp;пришлём вам справку на&nbsp;<nobr>{{ configs.orderInfo.contactEmail }}</nobr>.
            .vw-order-text(v-if = "configs.orderInfo.deliveryAddress") Оригинал справки будет отправлен Почтой России по&nbsp;адресу:<br>{{ configs.orderInfo.deliveryAddress }}
            .vw-order-text Если у&nbsp;вас остались вопросы, напишите нам на <nobr><a :href="`mailto:${configs.partner.email}`">{{ configs.partner.email }}</a></nobr>. При обращении укажите номер вашего заказа&nbsp;{{ configs.orderInfo.id }}.

      .vw-order(
        v-if = "orderPage === 'proccess'")
        .vw-success
          .vw-success-left
            .vw-success-left-loading
          .vw-success-right
            .vw-header Производится оплата заказа
            .vw-order-text Подождите, это может занять несколько минут.
            .vw-order-text Если у&nbsp;вас остались вопросы, напишите нам на <nobr><a :href="`mailto:${configs.partner.email}`">{{ configs.partner.email }}</a></nobr>. При обращении укажите номер вашего заказа&nbsp;{{ configs.orderInfo.id }}.

      .vw-order(
        v-if = "orderPage === 'error'")
        .vw-success
          .vw-success-left.left-error
            | <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 452.7 452.7" style="enable-background:new 0 0 450 450;" xml:space="preserve"><g><g><path class="st1" d="M280.5,226.3l164-164c5.1-5.1,7.9-11.8,7.9-19c0-7.2-2.8-14-7.9-19L428.4,8.2c-5.1-5.1-11.8-7.9-19-7.9 c-7.2,0-14,2.8-19,7.9l-164,163.9L62.4,8.2c-5.1-5.1-11.8-7.9-19-7.9c-7.2,0-14,2.8-19,7.9L8.2,24.3c-10.5,10.5-10.5,27.6,0,38.1 l164,164l-164,164c-5.1,5.1-7.9,11.8-7.9,19s2.8,14,7.9,19l16.1,16.1c5.1,5.1,11.8,7.9,19,7.9c7.2,0,14-2.8,19-7.9l164-164 l164,164c5.1,5.1,11.8,7.9,19,7.9h0c7.2,0,14-2.8,19-7.9l16.1-16.1c5.1-5.1,7.9-11.8,7.9-19s-2.8-14-7.9-19L280.5,226.3z"/></g></g></svg>
          .vw-success-right
            .vw-header Не удалось оплатить заказ
            .vw-order-button
              .vw-form-button(
                @click = "moreRequest()")
                .vw-form-button-text Повторить оплату
                .vw-form-button-loader
            .vw-order-text Если у&nbsp;вас остались вопросы, напишите нам на <nobr><a :href="`mailto:${configs.partner.email}`">{{ configs.partner.email }}</a></nobr>. При обращении укажите номер вашего заказа&nbsp;{{ configs.orderInfo.id }}.

</template>

<script>
import { mapState, mapMutations, mapGetters } from 'vuex'
import moment from 'moment'
import axios from 'axios'

import elements from './parts/elements'

export default {
  name: 'App',

  components: {
    elements
  },

  data () {
    return {
      orderPage: 'form',
      focus: null,
      orientation: null
    }
  },

  computed: {
    ...mapState({
      configs: state => state.configs,
      errors: state => state.errors,
      errorsShow: state => state.errorsShow,
      request: state => state.request,
      translates: state => state.translates,
      loading: state => state.loading
    }),
    ...mapGetters([
      'getError',
      'getFirstError',
      'getApiUrl'
    ])
  },

  created () {
    document.addEventListener('keydown', this.onKeyPress)
    this.$on('hook:beforeDestroy', () => document.removeEventListener('keydown', this.onKeyPress))

    // if (document.location.href.indexOf('references-widget.dev.vip-zal.ru') ||
    //     document.location.href.indexOf('localhost:')) {
    //   this.updateApiUrl('https://dev.vipzal.dev.vip-zal.ru')
    //   this.updateDev(true)
    // }
  },

  mounted () {
    this.init()

    setInterval(() => {
      this.sendIframeHeight()
    }, 100)

    // window.addEventListener('message', (e) => {
    //   if (e && e.data && typeof e.data === 'string' && e.data.indexOf('params') !== -1) {
    //     let params = JSON.parse(e.data).params
    //
    //     this.updateConfigs({
    //       contrast: params.contrast
    //     })
    //   }
    // })
  },

  methods: {
    ...mapMutations([
      'updateConfigs',
      'clearRequest',
      'clearErrors',
      'updateErrors',
      'updateDateSend',
      'updateLoading',
      'updateApiUrl',
      'updateDev',
      'showErrors',
      'excludeError'
    ]),

    moreRequest () {
      this.orderPage = 'proccess'

      const number = this.$route.query.pg_order_id.replace(/[^0-9]/g, '')
      const hash = this.$route.query.vz_hash

      let config = {
        method: 'post',
        url: `${this.getApiUrl()}/flight-inquiry/api/v1/payments/ignite/${number}?hash=${hash}`,
        headers: {
          'Content-Type': 'application/json'
        }
      }

      axios(config)
        .then((res) => {
          let response = res.data

          if (response.invoices && response.invoices[0] && response.invoices[0].paymentUrl) {
            if (!this.configs.dev) {
              window.parent.postMessage(JSON.stringify({
                widget: {
                  redirect: response.invoices[0].paymentUrl
                }
              }), '*')
            } else {
              window.location.href = response.invoices[0].paymentUrl
            }
          }
        })
    },

    analytics (event) {
      console.log('analytics:', event)

      this.$gtm.push({ event: event })
    },

    focusElement (e) {
      let position = e.split('|')
      this.focus = position[0]
      this.orientation = position[1]
    },

    getFocusElement (currentElement) {
      let response = false
      let scheme = []
      this.configs.schemeElements.forEach((x) => {
        if (typeof x === 'string') {
          if (x.indexOf('form') === -1) {
            scheme.push(x)
          }
        } else if (typeof x === 'object') {
          x.forEach((n) => {
            if (n.indexOf('form') === -1) {
              scheme.push(n)
            }
          })
        }
      })
      if (currentElement[0]) {
        scheme.forEach((x, i) => {
          if (currentElement[1] === 'next' && i < scheme.length - 1 && x === currentElement[0]) {
            response = scheme[i + 1] + '|up'
          }
          if (currentElement[1] === 'prev' && i > 0 && x === currentElement[0]) {
            response = scheme[i - 1] + '|down'
          }
        })
      }
      if (response) {
        this.focusElement(response)
      }
    },

    init () {
      if (this.$route.query.pg_order_id && this.$route.query.pg_order_id !== 'undefined' && this.$route.query.vz_hash && this.$route.query.vz_hash !== 'undefined') {
        this.paymentProcess()
      }
      this.updateConfigs(this.$route.query)
    },

    paymentProcess () {
      const number = this.$route.query.pg_order_id.replace(/[^0-9]/g, '')
      const hash = this.$route.query.vz_hash

      this.orderPage = 'proccess'

      let config = {
        method: 'get',
        url: `${this.getApiUrl()}/flight-inquiry/api/v1/orders/${number}?hash=${hash}`,
        headers: {
          'Content-Type': 'application/json'
        }
      }

      axios(config)
        .then((res) => {
          let response = res.data

          this.configs.orderInfo = response

          if (response.invoices[0].status.id === 1) {
            this.orderPage = 'proccess'

            if (this.$route.query.pg_failure_code && this.$route.query.pg_failure_code !== 'undefined') {
              this.orderPage = 'error'
              this.analytics('payment_failed')
            } else {
              setTimeout(() => {
                this.paymentProcess()
              }, 3000)
            }
          }
          if (response.invoices[0].status.id === 2) {
            this.orderPage = 'success'
            this.analytics('payment_success')
          }
          if (response.invoices[0].status.id === 3) {
            this.orderPage = 'error'
            this.analytics('payment_failed')
          }
        })
    },

    setRequest () {

      if (!this.loading) {
        if (this.errors && this.errors.length) {
          this.analytics('fields_error')
        }

        if (this.validationForm() && !this.loading) {
          this.updateLoading(true)

          let date = this.request.date

          if (date.length) {
            date = [ new Date(date[0]).getTime() / 1000, new Date(date[1]).getTime() / 1000 ]
          } else {
            date = [ new Date(date).getTime() / 1000 ]
          }

          let req = {
            arrivalAirportId: this.request.arrival.id,
            departureAirportId: this.request.departure.id,
            contactEmail: this.request.email,
            flightDate: date,
            flightType: this.request.flightType,
            fullNamePassenger: this.request.user,
            pageUrl: this.configs.host,
            tariffId: 1,
            type: this.request.type ? parseInt(this.request.type) : null,
            sourceHostId: this.configs.partner.id
          }

          if (this.request.fast[0].value) req.tariffId = 2

          if (this.request.flight && req.type !== 2) req.flightNumber =  this.request.flight
          if (this.request.comment) req.comment =  this.request.comment

          if (this.request.original[0].value) {
            if (this.request.original[0].other.value) {
              req.deliveryAddress = this.request.original[0].other.value
            }
          }

          let config = {
            method: 'post',
            url: `${this.getApiUrl()}/flight-inquiry/api/v1/orders`,
            headers: {
              'Content-Type': 'application/json'
            },
            data: JSON.stringify(req)
          }

          axios(config)
            .then((res) => {
              let response = res.data

              if (response.invoices && response.invoices[0].paymentUrl) {
                this.analytics('payment_started')

                if (!this.configs.dev) {
                  window.parent.postMessage(JSON.stringify({
                    widget: {
                      redirect: response.invoices[0].paymentUrl
                    }
                  }), '*')
                } else {
                  window.location.href = response.invoices[0].paymentUrl
                }

              }

              setTimeout(() => {
                this.updateLoading(false)
              }, 2000)
            })
            .catch((err) => {
              if (err && err.response && err.response.data) {
                this.updateErrors(err.response.data)
              }
              setTimeout(() => {
                this.updateLoading(false)
              }, 2000)
            })
        } else {
          this.showErrors(true)
        }
      }
    },

    changeElement (element) {
      if (this.errorsShow) {
        this.excludeError(element)
      }
    },

    validationForm () {
      let response = false

      this.clearErrors()

      Object.keys(this.configs.elementsSettings).forEach((x) => {
        const elementSettings = this.configs.elementsSettings[x]

        if (elementSettings && elementSettings.required) {
          let access = true

          // Иногда блок обязателен, иногда нет

          if (elementSettings.nameFieldOnJSON === 'flight' && this.request.type === '2') {
            access = false
          }

          if (access) {
            if (!this.request[elementSettings.nameFieldOnJSON]) {

              // Специальная валидация полей

              if (elementSettings.nameFieldOnJSON === 'flight') {
                let error = {}
                error[elementSettings.namesErrors[0]] = ['all']
                this.updateErrors(error)
                this.analytics('race_error')
              } else if (elementSettings.nameFieldOnJSON === 'button') {
                const value = this.request.accept
                if (value === false) {
                  let error = {}
                  error['accept'] = ['all']
                  this.updateErrors(error)
                }
              } else {
                let error = {}
                error[elementSettings.namesErrors[0]] = ['all']
                this.updateErrors(error)
              }

            } else {

              // Специальная валидация полей

              if (elementSettings.nameFieldOnJSON === 'departure' || elementSettings.nameFieldOnJSON === 'arrival') {
                const value = this.request[elementSettings.nameFieldOnJSON]
                if (!value || !value.name) {
                  let error = {}
                  error[elementSettings.namesErrors[0]] = ['all']
                  this.updateErrors(error)
                }
              }

              if (elementSettings.nameFieldOnJSON === 'original') {
                const value = this.request[elementSettings.nameFieldOnJSON][0].other.value
                if (this.request[elementSettings.nameFieldOnJSON][0].value) {
                  if (!value) {
                    let error = {}
                    error[elementSettings.namesErrors[0]] = ['all']
                    this.updateErrors(error)
                  }
                }
              }

              if (elementSettings.nameFieldOnJSON === 'flight') {
                const re = /[^0-9]+/g
                const value = this.request[elementSettings.nameFieldOnJSON].split(' ')[0]
                if (!value || value.length < 2 || !re.test(value)) {
                  let error = {}
                  error[elementSettings.namesErrors[0]] = ['all']
                  this.updateErrors(error)
                  this.analytics('race_error')
                }
              }

              if (elementSettings.nameFieldOnJSON === 'email') {
                const re = /^\w+@[a-zA-Z_]+?\.[a-zA-Z]{2,3}$/
                const value = this.request[elementSettings.nameFieldOnJSON].split(' ')[0]
                if (!value || !re.test(value)) {
                  let error = {}
                  error[elementSettings.namesErrors[0]] = ['all']
                  this.updateErrors(error)
                }
              }

            }
          }
        }
      })

      if (this.errors.length <= 0) {
        response = true
      } else {

        if (this.getFirstError()) {
          let firstError = Object.keys(this.getFirstError())[0] + 'Element'

          document.getElementById(firstError).scrollIntoView({
            behavior: 'smooth',
            block: 'start'
          })
        }

        if ((this.errors.length === 1 && this.getError('accept').length === 0) || (this.errors.length > 1)) {
          // this.analytics('red_error')
        }

        if (this.getError('accept').length > 0) {
          // this.analytics('fz_error')
        }
      }

      return response
    },

    onKeyPress (e) {
      this.focus = null

      if (e && [ 'ArrowUp', 'ArrowDown', 'Enter' ].includes(e.key)) {
        e.preventDefault()
      }

      if (e && [ 'ArrowUp' ].includes(e.key)) {
        if (e.target.id) {
          this.getFocusElement([ e.target.id, 'prev' ])
        }
      }

      if (e && [ 'Enter' ].includes(e.key)) {
        if (e.target.id) {
          this.getFocusElement([ e.target.id, 'next' ])
        }
      }

      if (e && [ 'ArrowDown' ].includes(e.key)) {
        if (e.target.id === 'date') {
          this.focus = 'calendar_' + moment().valueOf()
        } else {
          this.getFocusElement([ e.target.id, 'next' ])
        }
      }

      if (e && [ 'Tab' ].includes(e.key)) {
        if (e.target.id === 'date') {
          e.preventDefault()
          this.getFocusElement([ e.target.id, 'next' ])
        } else {
          if (!this.focus) {
            this.getFocusElement([ null, 'next' ])
          }
        }
      }
    },

    sendIframeHeight () {
      const height = document.getElementById('startElement').offsetHeight
      window.parent.postMessage(JSON.stringify({
        widget: {
          selector: this.configs.selector,
          heightIframe: height
        }
      }), '*')
    }

  }
}
</script>

<style lang="sass">

#app
  max-width: 800px

.part-error,
.part-error-w-input

  .vw-form-checkbox-icon,
  .vw-form-radio-icon
    border-color: var(--c-input-error)
    background-color: var(--c-input-error-background)

    & + .vw-form-checkbox-text,
    & + .vw-form-radio-text
      color: var(--c-input-error)

  .v2-date-wrap
    border-color: var(--c-input-error) !important
    background-color: var(--c-input-error-background) !important

.part-error,
  .vw-form-input-note,
  .vw-form-title,
  .vw-form-label
    color: var(--c-input-error)

  .vw-form-input
    border-color: var(--c-input-error)
    background-color: var(--c-input-error-background)

.vw-form-autocomplete
  width: 100%
  background-color: #fff
  border-radius: 4px
  padding-top: .5rem
  overflow: auto
  max-height: 240px !important
  border: 1px solid rgba(43, 54, 79, .6)
  margin: 1px 0
  left: 0
  position: absolute
  top: 100%
  z-index: 1

  &-group
    &-title
      color: #0a0a0a
      font-weight: bold
      font-size: 16px
      line-height: 37px
      overflow: hidden
      text-overflow: ellipsis
      text-align: left
      padding: 0 10px
      border-bottom: 1px solid #e7e7e7

      span
        font-weight: normal
        font-size: 16px
        line-height: 37px

      &.cursor
        cursor: pointer

        &:hover
          background: #eaeaea

    &-list
      &-item
        color: #0a0a0a
        font-size: 16px
        line-height: 37px
        overflow: hidden
        text-overflow: ellipsis
        text-align: left
        padding: 0 10px 0 30px
        border-bottom: 1px solid #e7e7e7
        cursor: pointer

        &:hover
          background: #eaeaea

    &:last-child
      .vw-form-autocomplete-group-list-item:last-child
        border-bottom: 0

.vw
  &-success
    display: flex
    align-items: flex-start
    justify-content: flex-start
    padding-top: 2px

    @media screen and (max-width: 500px)
      display: block

    .vw-order-button
      margin-bottom: 1rem
      margin-top: 0

    &-left
      flex: none
      width: 5rem
      margin-right: 1rem

      &.left-error
        @media screen and (max-width: 500px)
          margin-bottom: 1rem

        svg
          width: 50px
          height: 50px
          top: .35rem

      &-loading
        pointer-events: none
        height: 50px
        width: 50px
        border-width: 6px
        border-style: solid
        border-color: hsla(0, 0%, 0%, 0.75) hsla(0, 0%, 0%, 0.75) hsla(0, 0%, 0%, 0.25) hsla(0, 0%, 0%, 0.25)
        border-radius: 100%
        animation: clockwise .5s linear infinite
        transition: .3s
        position: relative
        top: 3px

        @media screen and (max-width: 500px)
          margin-bottom: 1rem

      svg
        width: 100%
        position: relative
        top: -.4928rem

        path
          fill: var(--c-button)

    &-right
      flex: 1

  &-header
    font-size: 3.5714rem
    margin-bottom: 1.7857rem
    padding-left: 1.4285rem

    @media screen and (max-width: 500px)
      font-size: 2.85rem

  &-location
    display: flex
    align-items: stretch
    justify-content: flex-start
    margin-bottom: 2.1428rem

    &-item
      border: 1px solid #000
      padding: .7142rem
      margin-right: 0
      cursor: pointer

      &:first-child
        border-top-left-radius: .3571rem
        border-bottom-left-radius: .3571rem

      &:last-child
        margin-right: 0
        border-top-right-radius: .3571rem
        border-bottom-right-radius: .3571rem

      &.active
        background: #000
        color: #fff
        cursor: default

  &-note
    margin-bottom: 1rem
    padding-left: 1.4285rem
    padding-right: 1.4285rem

    &-annotation
      max-width: 42.8571rem

      @media screen and (max-width: 500px)
        max-width: 100%

      ol
        padding: 0
        margin: 1rem 0 1rem 1.1rem
        list-style-position: outside

        li
          margin-bottom: .4rem

          &:last-child
            margin-bottom: 0

  &-order
    padding: 0 1.4285rem

    &-text
      font-size: 1.25rem
      line-height: 1.3
      margin-bottom: 1rem

      &:last-child
        margin-bottom: 0

      *
        font-size: 1.25rem
        line-height: 1.3

    &-head
      font-size: 2rem
      margin-bottom: 1rem

    &-button
      display: table
      margin-top: 3.1428rem

  &-form
    &-note
      margin-top: .5rem
      opacity: .6

    &-part
      margin-bottom: 1.0714rem

      &:last-child
        margin-bottom: 0

      &-item
        margin-bottom: .7142rem

        &:last-child
          margin-bottom: 0

      .item-checkbox
        display: table

    &-label
      width: 100%
      padding-bottom: .7142rem

    &-error
      width: 100%
      padding-top: .5rem
      color: var(--c-input-error)

    &-title
      font-size: 1.5rem
      margin-bottom: 1.0714rem

    &-button
      min-width: 14.2857rem
      padding: 1.1071rem 2.6428rem
      border-radius: var(--c-button-radius)
      background: var(--c-button)
      color: #fff
      border: 1px solid transparent
      cursor: pointer
      font-size: 1.1428rem
      transition: all .2s ease
      position: relative

      &:hover
        background: var(--c-button-hover)

      &:focus
        border-color: var(--c-input-focus)

      &-text
        opacity: 1
        transition: .3s
        font-size: 1.1428rem

      &-loader
        opacity: 0
        pointer-events: none
        height: 18px
        width: 18px
        border-width: 2px
        border-style: solid
        border-color: hsla(0, 0%, 0%, 0.75) hsla(0, 0%, 0%, 0.75) hsla(0, 0%, 0%, 0.25) hsla(0, 0%, 0%, 0.25)
        border-radius: 100%
        animation: clockwise .5s linear infinite
        position: absolute
        top: calc(50% - 9px)
        left: calc(50% - 9px)
        transition: .3s

      &.loading
        pointer-events: none
        background: #e1e1e1

        .vw-form-button-text
          opacity: 0

        .vw-form-button-loader
          opacity: 1

    &-group
      display: flex
      align-items: stretch
      justify-content: flex-start
      flex-wrap: wrap
      width: 100%

      &.has-label
        position: relative
        padding-top: 1.65rem

        .vw-form-label
          margin-bottom: 0
          padding-bottom: 0
          position: absolute
          top: 0
          left: 0

      &.group-wheelchair,
      &.group-psg
        .vw-form-group-part
          margin-bottom: 0

      &-head
        flex: none
        width: 100%
        margin-bottom: 1.0714rem

      &-button
        margin-top: -1.0714rem

        &-btn
          display: inline-block
          line-height: 1
          cursor: pointer
          border-bottom: 1px dashed rgba(0, 0, 0, .5)

      &-part
        flex: 1

        .vw-form-input
          margin-bottom: 0

        &.has-label
          position: relative
          padding-top: 1.65rem

          .vw-form-label
            margin-bottom: 0
            padding-bottom: 0
            position: absolute
            top: 0
            left: 0

        &.part-date
          width: 13.5714rem

        &.part-help-other
          padding-left: 2.2142rem

        &.part-type-wheelchair
          padding-left: 2.2142rem
          padding-bottom: .7142rem

          .vw-form-input.input-weight-wheelchair
            margin-top: .7142rem
            width: 7.8571rem

            & + .vw-form-label + .vw-form-input-note
              opacity: 1

            &:disabled
              & + .vw-form-label,
              & + .vw-form-label + .vw-form-input-note
                opacity: .3

            &:focus
              & + .vw-form-label
                color: var(--c-input-focus)

                & + .vw-form-input-note
                  color: var(--c-input-focus)

        &.part-weight-wheelchair
          display: flex
          align-items: center
          position: relative
          padding-left: calc(5.3rem + .7142rem)

          &.en
            padding-left: calc(8.5rem + .7142rem)

          .vw-form-label
            position: absolute
            left: 0
            top: .7142rem
            bottom: 0
            display: flex
            align-items: center
            justify-content: flex-start
            padding-bottom: 0
            margin-bottom: 0
            pointer-events: none

          .vw-form-input-note
            margin-top: .7142rem
            margin-left: .7142rem
            opacity: .3

    &-textarea
      resize: none

    &-input,
    &-textarea
      border: 1px solid var(--c-input)
      border-radius: .2142rem
      padding: 0 .7142rem
      margin-bottom: 0
      height: 3.5714rem
      transition: all .2s ease
      font-size: 1.1428571429rem
      color: #5d5d5d

      &:focus
        border-color: var(--c-input-focus)

        & + .vw-form-label
          color: var(--c-input-focus)

      &:last-child
        margin-right: 0

      &:disabled
        pointer-events: none
        background: none !important
        border-color: var(--c-input-disabled)
        background-color: #f5f5f5c0 !important

      &.input-error
        border-color: var(--c-input-error)
        background-color: var(--c-input-error-background)

      &.input-comment
        width: calc(100% - 1.4285rem)
        height: 7.1428rem
        padding: .7142rem
        margin-bottom: -4px

      &.input-date,
      &.input-flight,
      &.input-surname,
      &.input-middlename,
      &.input-email,
      &.input-phone
        width: 13.5714rem

      &.input-flight
        text-transform: uppercase

      &.input-name
        width: calc(100% - 1.4285rem)

      &.input-time
        width: 7.8571rem

      &.input-aviacompany,
      &.input-aviacompany-other,
      &.input-help-other
        width: 22.5rem

        @media screen and (max-width: 500px)
          width: calc(100vw - 24px - 1.4285rem * 2 - 1.4285rem)
          margin-right: 0

      &.textarea-help-other
        width: 40.65rem

        @media screen and (max-width: 500px)
          width: calc(100vw - 24px - 1.4285rem * 2 - 1.4285rem)
          margin-right: 0

      &.input-aviacompany
        & + .vw-form-checkbox
          margin-top: .7142rem

      &.input-aviacompany-other
        margin-top: .7142rem

    &-textarea
      padding: .7142rem
      height: 7.1428rem

    &-checkbox,
    &-radio
      display: flex
      align-items: flex-start
      justify-content: flex-start
      cursor: pointer
      margin-bottom: .7142rem

      &:last-child
        margin-bottom: 0

      &-input
        width: 0
        height: 0
        opacity: 0
        padding: 0
        margin: 0

        &:checked
          & + .vw-form-checkbox-icon,
          & + .vw-form-radio-icon
            svg
              opacity: 1

        &:focus
          & + .vw-form-checkbox-icon,
          & + .vw-form-radio-icon
            border-color: var(--c-input-focus)

            & + .vw-form-checkbox-text,
            & + .vw-form-radio-text
              color: var(--c-input-focus)

        &:disabled
          & + .vw-form-checkbox-icon,
          & + .vw-form-radio-icon
            opacity: .3
            pointer-events: none
            cursor: default

            & + .vw-form-checkbox-text,
            & + .vw-form-radio-text
              opacity: .3
              pointer-events: none
              cursor: default

      &-icon
        flex: none
        width: 1.5rem
        height: 1.5rem
        border: 1px solid var(--c-input)
        border-radius: .2142rem
        margin-right: .7142rem
        display: flex
        align-items: center
        justify-content: center

        svg
          opacity: 0
          width: 80%
          height: 80%

          path
            fill: #000

      &-text
        padding-top: .2rem
        max-width: calc(42.8571rem - 1.5rem - .7142rem)

        &-note
          display: block
          opacity: .3
          margin-top: .3571rem

        span
          display: inline
          line-height: 1
          color: var(--c-button)
          border-bottom: 1px dashed var(--c-button)
          cursor: pointer
          transition: .3s

          &:hover
            color: var(--c-button-hover)
            border-color: var(--c-button-hover)

    &-radio
      &-icon
        border-radius: 50%

        svg
          background: #000
          border-radius: 50%
          width: 60%
          height: 60%

.vw-success
  .vw-header
    padding-left: 0 !important

</style>
