<template lang="pug">

  .vw-form-part(
    :id = "`${settings.nameFieldOnJSON}Element`"
    :class = "[ `part-${settings.nameFieldOnJSON}`, computedErrors ? 'part-error': '' ]")

    .vw-form-part-item.item-checkbox(
      v-for = "(item, i) in request.fast"
      :class = "{ 'item-error': getError(settings.nameFieldOnJSON)[0] === item.name }"
      :key = "i")

      label.vw-form-checkbox
        input.vw-form-checkbox-input(
          v-model.trim = "item.value"
          type = "checkbox"
          @keydown = "changeInputFromKeyboard($event, 'item', item, i, request[settings.nameFieldOnJSON])"
          @change = "changeElement()"
          :ref = "`r_${settings.nameFieldOnJSON}_` + item.name"
          :tabindex = "i + 1")

        span.vw-form-checkbox-icon(
          :id = "`${settings.nameFieldOnJSON}_` + (i + 1)")
          icon-checked

        span.vw-form-checkbox-text(
          v-html = "translates.formHelp[item.name][configs.language]"
        )

</template>

<script>
import { mapState, mapGetters } from 'vuex'
import iconChecked from './../assets/icons/iconChecked'

export default {
  props: [ 'focus', 'orientation', 'settings' ],

  components: {
    iconChecked
  },

  computed: {
    ...mapState({
      configs: state => state.configs,
      errors: state => state.errors,
      request: state => state.request,
      translates: state => state.translates
    }),
    ...mapGetters([
      'getError'
    ]),
    computedErrors () {
      let errors = false
      if (this.settings.namesErrors && this.settings.namesErrors.length) {
        this.settings.namesErrors.forEach((x) => {
          if (this.getError(x) && this.getError(x)[0]) {
            if (this.getError(x)[0] === 'all') {
              errors = true
            } else {
              errors = this.getError(x)[0]
            }
          }
        })
      }
      return errors
    }
  },

  watch: {
    focus (val) {
      if (val === 'fast') {
        if (this.orientation === 'down') {
          let lastItem = this.request.fast[this.request.fast.length - 1]
          if (lastItem.other && lastItem.value) {
            this.$refs['r_nature_other_other'][0].focus()
          } else {
            this.$refs['r_nature_' + lastItem.name][0].focus()
          }
        } else {
          this.$refs['r_nature_' + this.request.fast[0].name][0].focus()
        }
      }
    }
  },

  methods: {

    changeElement () {
      this.$emit('changeElement', this.settings.nameFieldOnJSON)
    },

    changeInputFromKeyboard () {
      // if (e && [ 'Enter', ' ', 'ArrowUp', 'ArrowDown', 'Tab' ].includes(e.key)) {
      //   e.preventDefault()
      //
      //   if (type === 'other') {
      //     if (e.key == 'Enter') {
      //       this.$emit('getFocusElement', [ 'fast', 'next' ])
      //     }
      //
      //     if ([ 'ArrowUp' ].includes(e.key)) {
      //       this.$refs['r_nature_' + item.name][0].focus()
      //     }
      //
      //     if ([ 'ArrowDown', 'Tab' ].includes(e.key)) {
      //       this.$emit('getFocusElement', [ 'fast', 'next' ])
      //     }
      //   } else {
      //     if (e.key == ' ') {
      //       this.$refs['r_nature_' + arrayItems[iItem].name][0].click()
      //     }
      //
      //     if (e.key == 'ArrowRight') {
      //       if (arrayItems[iItem].other) {
      //         this.$nextTick(() => {
      //           if (this.$refs['r_nature_other_' + arrayItems[iItem].other.name]) {
      //             this.$refs['r_nature_other_' + arrayItems[iItem].other.name][0].focus()
      //           }
      //         })
      //       }
      //     }
      //
      //     if (e.key == 'Enter') {
      //       if (arrayItems[iItem].other) {
      //         this.$refs['r_nature_' + arrayItems[iItem].name][0].click()
      //
      //         this.$nextTick(() => {
      //           if (this.$refs['r_nature_other_' + arrayItems[iItem].other.name]) {
      //             this.$refs['r_nature_other_' + arrayItems[iItem].other.name][0].focus()
      //           }
      //         })
      //       } else {
      //         if (iItem + 1 <= arrayItems.length - 1) {
      //           this.$refs['r_nature_' + arrayItems[iItem].name][0].click()
      //           this.$refs['r_nature_' + arrayItems[iItem + 1].name][0].focus()
      //         } else {
      //           this.$refs['r_nature_' + arrayItems[iItem].name][0].click()
      //           this.$emit('getFocusElement', [ 'fast', 'next' ])
      //         }
      //       }
      //     }
      //
      //     if ([ 'ArrowUp' ].includes(e.key)) {
      //       if (iItem - 1 >= 0) {
      //         this.$refs['r_nature_' + arrayItems[iItem - 1].name][0].focus()
      //       } else {
      //         this.$emit('getFocusElement', [ 'fast', 'prev' ])
      //       }
      //     }
      //
      //     if ([ 'ArrowDown', 'Tab' ].includes(e.key)) {
      //       if (item.other && item.value) {
      //         this.$refs['r_nature_other_' + item.other.name][0].focus()
      //       } else {
      //         if (iItem + 1 <= arrayItems.length - 1) {
      //           this.$refs['r_nature_' + arrayItems[iItem + 1].name][0].focus()
      //         } else {
      //           this.$emit('getFocusElement', [ 'fast', 'next' ])
      //         }
      //       }
      //     }
      //   }
      //
      // }
    }

  }
}
</script>

<style scoped lang="sass">

.vw-form-note
  max-width: 40.65rem
  opacity: 1
  color: #000

.vw-form-part
  margin-bottom: 1.7857rem !important

.part-original
  .vw-form-part-item.item-checkbox
    width: 100%

    .vw-form-textarea.textarea-help-other
      width: calc(100% - 1.4285rem)

</style>
