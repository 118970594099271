import Vue from 'vue'
import VueRouter from 'vue-router'
import VueYandexMetrika from 'vue-yandex-metrika'
import VueTagManager from 'vue-tag-manager'
import VueAnalytics from 'vue-analytics'
import V2Datepicker from 'v2-datepicker'

import App from './App.vue'
import store from './store'

import './assets/styles.scss'
import 'v2-datepicker/lib/index.css'

Vue.config.productionTip = false

Vue.use(V2Datepicker)

Vue.use(VueRouter)

Vue.use(VueYandexMetrika, {
  id: 68207341,
  env: 'production'
})

Vue.use(VueTagManager, {
  gtmId: 'GTM-NWR2P4Z'
})

Vue.use(VueAnalytics, {
  id: 'UA-16631088-45'
})

const router = new VueRouter({
  mode: 'history',
  base: __dirname,
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  }
})

new Vue({
  store,
  router,
  render: h => h(App)
}).$mount('#app')
