<template lang="pug">

  .vw-form-part(
    :id = "`${settings.nameFieldOnJSON}Element`"
    :class = "[ `part-${settings.nameFieldOnJSON}`, computedErrors ? 'part-error': '' ]")

    .vw-form-group-part.has-label
      input(:name="`vw-form-${settings.nameFieldOnJSON}`" type="text" class="input-hidden")
      input.vw-form-input.input-flight(
        :id = "settings.nameFieldOnJSON"
        v-model.trim = "request[settings.nameFieldOnJSON]"
        type = "text"
        :name = "`vw-form-${settings.nameFieldOnJSON}`"
        @input = "changeElement()"
        @change = "changeElementAnalytics()"
        @blur = "blurField()"
        :ref = "settings.nameFieldOnJSON")

      .vw-form-label(
        v-if = "settings.translateKeyLabel"
        v-html = "translates[settings.translateKeyLabel][configs.language]")

      .vw-form-note(
        v-if = "settings.translateKeyNote"
        v-html = "translates[settings.translateKeyNote][configs.language]")

      .vw-form-error(
        v-if = "computedErrors !== true && computedErrors !== false && configs.showErrors") {{ computedErrors }}

</template>

<script>
  import { mapState, mapGetters } from 'vuex'
  import Inputmask from 'inputmask'

  export default {
    props: [ 'focus', 'orientation', 'settings' ],

    computed: {
      ...mapState({
        configs: state => state.configs,
        request: state => state.request,
        translates: state => state.translates
      }),
      ...mapGetters([
        'getError'
      ]),
      computedErrors () {
        let errors = false
        if (this.settings.namesErrors && this.settings.namesErrors.length) {
          this.settings.namesErrors.forEach((x) => {
            if (this.getError(x) && this.getError(x)[0]) {
              if (this.getError(x)[0] === 'all') {
                errors = true
              } else {
                errors = this.getError(x)[0]
              }
            }
          })
        }
        return errors
      }
    },

    watch: {
      focus (val) {
        if (val === 'flight') {
          this.$refs.flight.focus()
        }
      }
    },

    mounted () {
      this.$nextTick(() => {
        Inputmask({
          mask: '*{2} 9{1,4}',
          showMaskOnHover: false
        }).mask(document.getElementById(this.settings.nameFieldOnJSON))
      })
    },

    methods: {

      blurField () {
        // if (this.request[this.settings.nameFieldOnJSON] && this.request[this.settings.nameFieldOnJSON].length > 0) {
        //   this.$emit('analytics', 'race_added')
        // }
      },

      changeElementAnalytics () {
        if (this.settings.analyticsChangeEvent) {
          this.$emit('analytics', this.settings.analyticsChangeEvent)
        }
      },

      changeElement () {
        this.$emit('changeElement', this.settings.nameFieldOnJSON)
      }

    }
  }
</script>

<style scoped lang="sass">

</style>
