<template lang="pug">

  div
    .vw-form-part(
      :id = "`${settings.nameFieldOnJSON}Element`"
      :class = "[ `part-${settings.nameFieldOnJSON}`, computedErrors ? 'part-error': '' ]")

      .vw-form-group-part
        label.vw-form-checkbox
          input.vw-form-checkbox-input(
            v-model.trim = "request.accept"
            type = "checkbox"
            @keydown = "changeInputFromKeyboard($event, 'accept')"
            @change = "changeElement()"
            ref = "accept")

          span.vw-form-checkbox-icon
            icon-checked

          span.vw-form-checkbox-text {{ translates.formAgree1[configs.language] }} <span @click.prevent="openModal('rules')">{{ translates.formAgree2[configs.language] }}</span> {{ translates.formAgree3[configs.language] }} <span @click.prevent="openModal('policy')">{{ translates.formAgree4[configs.language] }}</span>

    .vw-form-part.part-button
      .vw-form-group-part
        button.vw-form-button(
          :class = "{ 'loading': loading }"
          @keydown = "changeInputFromKeyboard($event, 'button')"
          type = "submit"
          ref = "button")
          .vw-form-button-text(v-if = "!request.fast[0].value") {{ translates[settings.translateKeyLabel][configs.language] }} 1 670 ₽
          .vw-form-button-text(v-if = "request.fast[0].value") {{ translates[settings.translateKeyLabel][configs.language] }} 2 228 ₽
          .vw-form-button-loader

</template>

<script>
  import { mapState, mapGetters } from 'vuex'
  import iconChecked from './../assets/icons/iconChecked'

  export default {
    props: [ 'focus', 'orientation', 'settings' ],

    components: {
      iconChecked
    },

    computed: {
      ...mapState({
        configs: state => state.configs,
        request: state => state.request,
        translates: state => state.translates,
        loading: state => state.loading
      }),
      ...mapGetters([
        'getError'
      ]),
      computedErrors () {
        let errors = false
        if (this.settings.namesErrors && this.settings.namesErrors.length) {
          this.settings.namesErrors.forEach((x) => {
            if (this.getError(x) && this.getError(x)[0]) {
              if (this.getError(x)[0] === 'all') {
                errors = true
              } else {
                errors = this.getError(x)[0]
              }
            }
          })
        }
        return errors
      }
    },

    watch: {
      focus (val) {
        if (val === 'button') {
          this.$refs.accept.focus()
        }
      }
    },

    methods: {

      changeElement () {
        this.$emit('changeElement', 'accept')
      },

      openModal (type) {
        window.parent.postMessage(JSON.stringify({
          widget: {
            modal: {
              type: type,
              lang: this.configs.language,
              theme: this.configs.theme
            }
          }
        }), '*')
      },

      changeInputFromKeyboard () {
        // if (e && [ 'Enter', 'ArrowUp', 'ArrowDown', 'Tab' ].includes(e.key)) {
        //   e.preventDefault()
        //
        //   if (e.key == 'Enter') {
        //     if (type === 'accept') {
        //       this.$refs.accept.click()
        //       this.$refs.button.focus()
        //     }
        //
        //     if (type === 'button') {
        //       this.$emit('sendForm', true)
        //     }
        //   }
        //
        //   if ([ 'ArrowDown', 'Tab' ].includes(e.key)) {
        //     if (type === 'accept') {
        //       this.$refs.button.focus()
        //     }
        //   }
        //
        //   if ([ 'ArrowUp' ].includes(e.key)) {
        //     if (type === 'accept') {
        //       this.$emit('getFocusElement', [ 'button', 'prev' ])
        //     }
        //
        //     if (type === 'button') {
        //       this.$refs.accept.focus()
        //     }
        //   }
        //
        // }
      }

    }
  }
</script>

<style scoped lang="sass">

.vw-form-button
  margin-bottom: 1rem

</style>
