<template lang="pug">

  .vw-form-part(
    :id = "`${settings.nameFieldOnJSON}Element`"
    :class = "[ `part-${settings.nameFieldOnJSON}`, computedErrors ? 'part-error': '' ]")

    .vw-form-group-part.part-date.has-label
      v2-datepicker-range(
        v-if = "this.request.type === '2'"
        v-model.trim = "request[settings.nameFieldOnJSON]"
        lang = "ru"
        format = "dd.MM.yyyy"
        placeholder = "__.__.____"
        range-separator = "—"
        :customLocals = "locals"
        :picker-options = "options"
        @change = "changeElement()")

      v2-datepicker(
        v-if = "this.request.type !== '2'"
        v-model.trim = "request[settings.nameFieldOnJSON]"
        lang = "ru"
        format = "dd.MM.yyyy"
        placeholder = "__.__.____"
        :customLocals = "locals"
        :picker-options = "options"
        @change = "changeElement()")

      .vw-form-label(
        v-if = "this.request.type !== '2'"
        v-html = "translates[settings.translateKeyLabelSingle][configs.language]")

      .vw-form-label(
        v-if = "this.request.type === '2'"
        v-html = "translates[settings.translateKeyLabelPlural][configs.language]")

      .vw-form-error(
        v-if = "computedErrors !== true && computedErrors !== false && configs.showErrors") {{ computedErrors }}

</template>

<script>
import { mapState, mapGetters } from 'vuex'

export default {
  props: [ 'focus', 'orientation', 'settings' ],

  data () {
    return {
      show: false,

      locals: {
        ru: {
          months: {
            'original': ['Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь', 'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь'],
            'abbr': ['Gen', 'Feb', 'Mar', 'Apr', 'Mag', 'Giu', 'Lug', 'Ago', 'Set', 'Ott', 'Nov', 'Dic']
          },
          days: ['ПН', 'ВТ', 'СР', 'ЧТ', 'ПТ', 'СБ', 'ВС']
        }
      },

      options: {
        disabledDate (time) {
          return time.getTime() > Date.now();
        }
      }
    }
  },

  watch: {
    focus (val) {
      if (val === 'date') {
        this.$refs.date.focus()
      }
    }
  },

  computed: {
    ...mapState({
      configs: state => state.configs,
      request: state => state.request,
      translates: state => state.translates
    }),
    ...mapGetters([
      'getError'
    ]),
    computedErrors () {
      let errors = false
      if (this.settings.namesErrors && this.settings.namesErrors.length) {
        this.settings.namesErrors.forEach((x) => {
          if (this.getError(x) && this.getError(x)[0]) {
            if (this.getError(x)[0] === 'all') {
              errors = true
            } else {
              errors = this.getError(x)[0]
            }
          }
        })
      }
      return errors
    }
  },

  methods: {

    changeElementAnalytics () {
      if (this.settings.analyticsChangeEvent) {
        this.$emit('analytics', this.settings.analyticsChangeEvent)
      }
    },

    hideCalendar () {
      setTimeout(() => {
        this.show = false
      }, 100)
    },

    changeElement () {
      this.$emit('changeElement', this.settings.nameFieldOnJSON)
    },

    setDate (date) {
      this.request[this.settings.nameFieldOnJSON] = date
      this.changeElementAnalytics()
    },

    getFocusElement (data) {
      this.$emit('getFocusElement', data)
    },

    focusElement (data) {
      this.$emit('focusElement', data)
    }

  }
}
</script>

<style lang="sass">

.part-date
  position: relative

  .v2-date-wrap
    border: 1px solid var(--c-input)
    border-radius: .2142rem
    margin-bottom: 0
    height: 3.5714rem
    transition: all .2s ease

    .v2-picker-trigger
      padding: 0 .7142rem
      font-size: 1.1428571429rem
      color: #5d5d5d
      height: 3.5714rem
      line-height: 3.5714rem

    .v2-date-clear,
    .v2-date-icon
      display: none

.v2-picker-panel-wrap
  box-shadow: none !important
  border-color: var(--c-input) !important
  border-radius: .2142rem !important
  width: calc(36.5714rem / 2) !important

  &.v2-picker-range-panel-wrap
    width: 36.5714rem !important

  .v2-picker-panel__header
    padding: 0
    margin: 0

    .v2-picker-header__toggle
      height: .9285rem
      line-height: 1

      &.v2-picker-header__toggle-prev
        left: 1.0714rem
        top: 1.0428rem

      .v2-toggle-icon
        height: .9285rem
        width: .5rem

        &.v2-toggle-icon__prev-year,
        &.v2-toggle-icon__next-year
          display: none

        &.v2-toggle-icon__prev-month::before
          top: .35rem !important

        &.v2-toggle-icon__next-month::before
          top: .67rem !important

        &.v2-toggle-icon__prev-month::before,
        &.v2-toggle-icon__next-month::before
          width: .5rem
          height: .5rem
          opacity: .3

        &.v2-toggle-icon__next-month::before
          right: .75rem !important
          border-bottom-color: #005d87
          border-right-color: #005d87

        &.v2-toggle-icon__prev-month::before
          left: .6rem !important
          border-top-color: #005d87
          border-left-color: #005d87

        &.v2-toggle-icon__prev-month:hover::before,
        &.v2-toggle-icon__next-month:hover::before
          opacity: 1

    .v2-picker-header__label
      height: 3.2857rem
      display: flex
      align-items: center
      justify-content: center

      .v2-picker-header__label-text
        font-size: 1.2857rem !important
        font-weight: normal !important
        padding: 0 !important

  .v2-picker-panel__table
    width: 100%

  .v2-picker-panel__table-row.v2-picker-panel__week-label
    span
      padding: 0
      line-height: 2.1428rem
      font-size: 1rem
      color: #000
      border: 0

      &:nth-child(6),
      &:nth-child(7)
        color: #d8afa8

  .v2-picker-range-panel
    border: 0 !important
    width: 50% !important
    padding: 0 0 1.0714rem !important

    .v2-picker-panel__content
      padding: 0 1.0714rem !important
      width: 100% !important

  .v2-picker-panel__table-cell
    font-size: 1rem !important
    padding: 0 !important
    width: 14.28571%
    height: 2rem

    span
      margin-right: 0 !important
      width: 2rem
      height: 2rem
      line-height: 1
      display: flex
      align-items: center
      justify-content: center

    //&.today
    //  background-color: #e9e9e9
    //  border-radius: 50%

    &.prev-month,
    &.next-month
      color: rgba(0, 0, 0, .1) !important

    &.disabled
      background: none !important

      span
        background: none !important

    &.normal,
    &.today
      color: #000

      &:hover
        color: #019ebd !important

      &:nth-child(6),
      &:nth-child(7),
      &:nth-child(13),
      &:nth-child(14),
      &:nth-child(20),
      &:nth-child(21),
      &:nth-child(27),
      &:nth-child(28),
      &:nth-child(34),
      &:nth-child(35)
        color: #ad1414

    &.in-range
      background-color: rgba(0, 0, 0, .05) !important

    &.selected
      span
        background-color: var(--c-input-focus) !important

    &.start-date
      border-top-left-radius: 50%
      border-bottom-left-radius: 50%

      span
        background-color: var(--c-input-focus) !important

    &.end-date
      border-top-right-radius: 50%
      border-bottom-right-radius: 50%

      span
        background-color: var(--c-input-focus) !important

</style>
