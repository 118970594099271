<template lang="pug">

  | <svg version="1.1" id="Слой_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 106.4 131.4" style="enable-background:new 0 0 106.4 131.4;" xml:space="preserve"><path class="st0" d="M46.5,121.7l-9.3,9.7L0,93.1l23.7-27.2h18.5L19.7,86.4l80-1.6v14.7l-79.5-0.6L46.5,121.7z"/><path class="st0" d="M63.9,65.9L86.6,45l-80,1.6V31.9l79.5,0.6L59.8,9.7L69.1,0l37.2,38.4L82.3,65.9H63.9z"/></svg>

</template>

<script>
  export default {
  }
</script>

<style scoped lang="sass">

</style>
