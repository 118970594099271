<template lang="pug">

  .vw-form-elements-part(
    v-if = "getSettingsElement(part) && getSettingsElement(part).type"
  )

    part-autocomplete-airports(
      v-if = "getSettingsElement(part).type === 'autocompleteAirports'"
      :focus = "focus"
      :orientation = "orientation"
      :settings = "getSettingsElement(part)"
      @getFocusElement = "getFocusElement"
      @focusElement = "focusElement"
      @changeElement = "changeElement"
      @analytics = "analytics"
    )

    part-radio-list(
      v-if = "getSettingsElement(part).type === 'radioList'"
      :focus = "focus"
      :orientation = "orientation"
      :settings = "getSettingsElement(part)"
      @getFocusElement = "getFocusElement"
      @focusElement = "focusElement"
      @changeElement = "changeElement"
      @analytics = "analytics"
    )

    part-flight(
      v-if = "getSettingsElement(part).type === 'flight'"
      :focus = "focus"
      :orientation = "orientation"
      :settings = "getSettingsElement(part)"
      @getFocusElement = "getFocusElement"
      @focusElement = "focusElement"
      @changeElement = "changeElement"
      @analytics = "analytics")

    part-calendar-single-to-plural(
      v-if = "getSettingsElement(part).type === 'calendarSingleToPlural'"
      :focus = "focus"
      :orientation = "orientation"
      :settings = "getSettingsElement(part)"
      @getFocusElement = "getFocusElement"
      @focusElement = "focusElement"
      @changeElement = "changeElement"
      @analytics = "analytics")

    part-input(
      v-if = "getSettingsElement(part).type === 'input'"
      :focus = "focus"
      :orientation = "orientation"
      :settings = "getSettingsElement(part)"
      @getFocusElement = "getFocusElement"
      @focusElement = "focusElement"
      @changeElement = "changeElement"
      @analytics = "analytics")

    part-email(
      v-if = "getSettingsElement(part).type === 'email'"
      :focus = "focus"
      :orientation = "orientation"
      :settings = "getSettingsElement(part)"
      @getFocusElement = "getFocusElement"
      @focusElement = "focusElement"
      @changeElement = "changeElement"
      @analytics = "analytics")

    part-comment(
      v-if = "getSettingsElement(part).type === 'textarea'"
      :focus = "focus"
      :orientation = "orientation"
      :settings = "getSettingsElement(part)"
      @getFocusElement = "getFocusElement"
      @focusElement = "focusElement"
      @changeElement = "changeElement"
      @analytics = "analytics")

    part-send-button(
      v-if = "getSettingsElement(part).type === 'sendButton'"
      :focus = "focus"
      :orientation = "orientation"
      :settings = "getSettingsElement(part)"
      @getFocusElement = "getFocusElement"
      @focusElement = "focusElement"
      @changeElement = "changeElement"
      @analytics = "analytics")

    part-checkbox(
      v-if = "getSettingsElement(part).type === 'checkbox'"
      :focus = "focus"
      :orientation = "orientation"
      :settings = "getSettingsElement(part)"
      @getFocusElement = "getFocusElement"
      @focusElement = "focusElement"
      @changeElement = "changeElement"
      @analytics = "analytics")

    part-checkbox-with-textarea(
      v-if = "getSettingsElement(part).type === 'checkboxWithTextarea'"
      :focus = "focus"
      :orientation = "orientation"
      :settings = "getSettingsElement(part)"
      @getFocusElement = "getFocusElement"
      @focusElement = "focusElement"
      @changeElement = "changeElement"
      @analytics = "analytics")

    part-flight-type(
      v-if = "getSettingsElement(part).type === 'flightType'"
      :focus = "focus"
      :orientation = "orientation"
      :settings = "getSettingsElement(part)"
      @getFocusElement = "getFocusElement"
      @focusElement = "focusElement"
      @changeElement = "changeElement"
      @analytics = "analytics")

</template>

<script>
import { mapState, mapGetters } from 'vuex'

import partAutocompleteAirports from './part-autocomplete-airports'
import partRadioList from './part-radio-list'
import partFlight from './part-flight'
import partCalendarSingleToPlural from './part-calendar-single-to-plural'
import partInput from './part-input'
import partEmail from './part-email'
import partComment from './part-comment'
import partSendButton from './part-send-button'
import partCheckboxWithTextarea from './part-checkbox-with-textarea'
import partCheckbox from './part-checkbox'
import partFlightType from './part-flight-type'

export default {
  props: [ 'part', 'focus', 'orientation' ],

  components: {
    partAutocompleteAirports,
    partRadioList,
    partFlight,
    partCalendarSingleToPlural,
    partInput,
    partEmail,
    partComment,
    partSendButton,
    partCheckboxWithTextarea,
    partFlightType,
    partCheckbox
  },

  computed: {
    ...mapState({
      configs: state => state.configs,
      request: state => state.request,
      translates: state => state.translates
    }),
    ...mapGetters([
      'getSettingsElement'
    ])
  },

  methods: {
    analytics (event) {
      this.$emit('analytics', event)
    },

    getFocusElement (data) {
      this.$emit('getFocusElement', data)
    },

    focusElement (data) {
      this.$emit('focusElement', data)
    },

    changeElement (data) {
      this.$emit('changeElement', data)
    }
  }
}
</script>

<style scoped lang="sass">

</style>
