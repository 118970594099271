import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    configs: {
      dev: false,
      apiUrl: 'https://vip-zal.ru',
      pageUrl: 'https://vip-zal.ru/order3',
      ga: null,
      ym: null,
      mlayer: null,
      host: null,
      dates: {
        minYear: 1980,
        maxYear: 2020
      },
      theme: {
        input: 'rgba(43, 54, 79, .6)',
        inputFocus: '#0480d6',
        inputError: 'rgba(186, 28, 0, .9)',
        inputErrorBackground: '#fbf3f2',
        primary: '#0469d1',
        secondary: '#3687da',
        button_radius: '3.5714rem',
        groupBackground: '#0469d11f'
      },
      partner: {
        id: '84c639ff-b824-3515-ae57-9e8cd3785375',
        email: 'inquiry@krr.aero'
      },
      orderInfo: {},
      language: 'ru',
      selector: null,
      contrast: false,
      showErrors: true,
      schemeElements: [
        'fast',
        'type',
        [
          'formFlightData',
          'flight',
          'departure||flightType||arrival',
          'date'
        ],
        'formContactsData',
        'user',
        'email',
        'original',
        'comment',
        'button'
      ],
      elementsSettings: {
        fast: {
          type: 'checkbox',
          namesErrors: [ 'fast' ],
          nameFieldOnJSON: 'fast',
          analyticsChangeEvent: 'pass_fast',
          required: false
        },
        type: {
          type: 'radioList',
          namesErrors: [ 'type', 'typeInquiry' ],
          nameFieldOnJSON: 'type',
          analyticsChangeEvent: 'pass_type',
          translateKeyLabel: 'formType',
          required: true
        },
        flight: {
          type: 'flight',
          namesErrors: [ 'flight', 'flightNumber' ],
          nameFieldOnJSON: 'flight',
          analyticsChangeEvent: 'race_added',
          translateKeyLabel: 'formFlight',
          translateKeyNote: 'formFlightHelp',
          required: true
        },
        flightType: {
          type: 'flightType',
          namesErrors: [ 'flightType' ],
          nameFieldOnJSON: 'flightType',
          analyticsChangeEvent: 'pass_flightType',
          translateKeyTitle: 'formOriginalHelp',
          required: true
        },
        departure: {
          type: 'autocompleteAirports',
          namesErrors: [ 'departure', 'departureAirportId' ],
          nameFieldOnJSON: 'departure',
          analyticsChangeEvent: 'pass_departure',
          translateKeyLabel: 'formDeparture',
          required: true
        },
        arrival: {
          type: 'autocompleteAirports',
          namesErrors: [ 'arrival', 'arrivalAirportId' ],
          nameFieldOnJSON: 'arrival',
          analyticsChangeEvent: 'airport_added',
          translateKeyLabel: 'formArrival',
          required: true
        },
        date: {
          type: 'calendarSingleToPlural',
          namesErrors: [ 'date', 'flightDate' ],
          nameFieldOnJSON: 'date',
          analyticsChangeEvent: 'pass_date',
          translateKeyLabelSingle: 'formDateSingle',
          translateKeyLabelPlural: 'formDateRange',
          required: true
        },
        user: {
          type: 'input',
          namesErrors: [ 'user', 'fullNamePassenger' ],
          nameFieldOnJSON: 'user',
          analyticsChangeEvent: 'fio_added',
          translateKeyLabel: 'formUser',
          translateKeyNote: 'formUserNote',
          required: true
        },
        email: {
          type: 'email',
          namesErrors: [ 'email', 'contactEmail' ],
          nameFieldOnJSON: 'email',
          analyticsChangeEvent: 'pass_email',
          translateKeyLabel: 'formEmail',
          required: true
        },
        comment: {
          type: 'textarea',
          namesErrors: [ 'comment' ],
          nameFieldOnJSON: 'comment',
          analyticsChangeEvent: 'pass_comment',
          translateKeyLabel: 'formComment',
          required: false
        },
        original: {
          type: 'checkboxWithTextarea',
          namesErrors: [ 'original' ],
          nameFieldOnJSON: 'original',
          analyticsChangeEvent: 'orig_addr_added',
          translateKeyNote: 'formOriginalHelp',
          required: true
        },
        button: {
          type: 'sendButton',
          namesErrors: [ 'accept' ],
          nameFieldOnJSON: 'button',
          analyticsChangeEvent: '',
          translateKeyLabel: 'formSend',
          required: true
        }
      }
    },

    errors: [],
    errorsShow: false,
    loading: false,

    request: {
      date: null,
      time: null,
      flight: null,
      flightType: 'departure',
      user: null,
      email: null,
      departure: {},
      arrival: {},
      comment: null,
      accept: false,
      dateSend: null,
      type: null,
      typeInquiry: [
        {
          name: '1',
          value: false
        },
        {
          name: '3',
          value: false
        },
        {
          name: '4',
          value: false
        },
        {
          name: '2',
          value: false
        }
      ],
      original: [
        {
          name: 'v6',
          value: false,
          other: {
            name: 'other',
            value: null
          }
        }
      ],
      fast: [
        {
          name: 'v7',
          value: false,
          other: {
            name: 'other',
            value: null
          }
        }
      ]
    },

    translates: {

      formComment: {
        ru: 'Дополнительная информация',
        en: ''
      },

      mailSubject: {
        ru: 'Заявка на помощь',
        en: 'Request for assistance'
      },

      formFlightData: {
        ru: 'Информация о рейсе',
        en: ''
      },

      formContactsData: {
        ru: 'Контактная информация',
        en: ''
      },

      formTitle: {
        ru: 'Заказ справок',
        en: 'Заказ справок'
      },

      formNote: {
        ru: '<p>Стоимость услуги&nbsp;&mdash; 1 670&nbsp;₽.</p><p>В&nbsp;течение 3-х рабочих дней мы&nbsp;отправим вам справку на&nbsp;e-mail. Если нужен оригинал справки, укажите адрес доставки, и&nbsp;мы&nbsp;отправим вам его Почтой России.</p>',
        en: ''
      },

      formDateSingle: {
        ru: 'Дата',
        en: ''
      },

      formDateRange: {
        ru: 'Даты с... по...',
        en: ''
      },

      formFlight: {
        ru: 'Номер рейса',
        en: ''
      },

      formFlightHelp: {
        ru: 'Например <i>SU 1234</i>',
        en: 'For example <i>SU 1234</i>'
      },

      formUser: {
        ru: 'ФИО получателя',
        en: ''
      },

      formUserNote: {
        ru: 'Например <i>Иванов Пётр Сергеевич</i>',
        en: ''
      },

      formEmail: {
        ru: 'E-mail',
        en: ''
      },

      formDeparture: {
        ru: 'Аэропорт вылета',
        en: ''
      },

      formArrival: {
        ru: 'Аэропорт прилёта',
        en: ''
      },

      formOriginalHelp: {
        ru: 'Оригинал справки будет отправлен по&nbsp;этому адресу Почтой России в&nbsp;течение 3&nbsp;рабочих дней. Стоимость отправки включена в&nbsp;стоимость заказа.',
        en: ''
      },

      formType: {
        ru: 'Тип справки',
        en: ''
      },

      formHelp: {
        v6: {
          ru: 'Требуется оригинал справки',
          en: '',
          notes: {
            ru: '',
            en: ''
          },

          other: {
            ru: 'Адрес доставки, начиная с индекса',
            en: ''
          }
        },
        v7: {
          ru: 'Срочное оформление + 558&nbsp;₽&nbsp;&mdash; на&nbsp;следующий <strong>рабочий</strong> день после оплаты заказа.',
          en: '',
          notes: {
            ru: '',
            en: ''
          }
        }
      },

      formBottom: {
        ru: '<p>Обработку заказов и&nbsp;прием платежей осуществляет ООО &laquo;Трэвелмарт&raquo;.</p>',
        en: ''
      },

      formFree: {
        ru: '<p>Справка выдаётся бесплатно следующим лицам и&nbsp;организациям:</p><ol><li>Федеральные органы законодательной и&nbsp;исполнительной власти, а&nbsp;также органы законодательной и&nbsp;исполнительной власти субъектов Российской Федерации и&nbsp;органы местного самоуправления при условии направления мотивированного (с&nbsp;обязательным указанием основания (номер материала/иное аналогичное) и&nbsp;цели) запроса в&nbsp;рамках их&nbsp;полномочий для целей исполнения возложенных на&nbsp;них задач и&nbsp;функций.</li><li>Органы судебной власти в&nbsp;рамках рассмотрения судебных дел.</li><li>Адвокаты при оказании ими юридической помощи при предоставлении документов, подтверждающих полномочия адвоката и&nbsp;его право действовать от&nbsp;имени и&nbsp;в&nbsp;интересах доверителя: ордер (или доверенность), удостоверение адвоката.</li></ol><p>Для бесплатного оформления справки необходимо направить запрос на&nbsp;электронный адрес <a href="mailto:info@krr.aero">info@krr.aero</a>.</p>',
        en: ''
      },

      formTypeInquiry: {
        title: {
          ru: 'Тип справки',
          en: 'Тип справки'
        },

        '2': {
          ru: 'Отсутствие / наличие рейса',
          en: 'Отсутствие / наличие рейса'
        },

        '1': {
          ru: 'Отмена рейса',
          en: 'Отмена рейса'
        },

        '3': {
          ru: 'Задержка рейса',
          en: 'Задержка рейса'
        },

        '4': {
          ru: 'Перенос рейса',
          en: 'Перенос рейса'
        }
      },

      formAgree1: {
        ru: 'Оформляя заказ, вы соглашаетесь с',
        en: 'By placing an order, you agree to'
      },

      formAgree2: {
        ru: 'Правилами оказания услуги',
        en: 'Terms of Service'
      },

      formAgree3: {
        ru: 'и',
        en: 'and'
      },

      formAgree4: {
        ru: 'Политикой в отношении обработки персональных данных',
        en: 'Personal data processing policy'
      },

      formSend: {
        ru: 'Оплатить',
        en: ''
      },

      calendarWeeks: [
        { ru: 'ПН', en: 'Mon' },
        { ru: 'ВТ', en: 'Tue' },
        { ru: 'СР', en: 'Wed' },
        { ru: 'ЧТ', en: 'Thu' },
        { ru: 'ПТ', en: 'Fri' },
        { ru: 'СБ', en: 'Sat' },
        { ru: 'ВС', en: 'Sun' }
      ],

      calendarMonth: [
        { ru: 'Январь', en: 'January' },
        { ru: 'Февраль', en: 'February' },
        { ru: 'Март', en: 'March' },
        { ru: 'Апрель', en: 'April' },
        { ru: 'Май', en: 'May' },
        { ru: 'Июнь', en: 'June' },
        { ru: 'Июль', en: 'July' },
        { ru: 'Август', en: 'August' },
        { ru: 'Сентябрь', en: 'September' },
        { ru: 'Октябрь', en: 'October' },
        { ru: 'Ноябрь', en: 'November' },
        { ru: 'Декабрь', en: 'December' }
      ]

    }
  },

  mutations: {
    updateApiUrl (state, data) {
      state.configs.apiUrl = data
    },
    updateDev (state, data) {
      state.configs.dev = data
    },
    updateLoading (state, data) {
      state.loading = data
    },
    updateDateSend (state, data) {
      state.request.dateSend = data
    },
    clearErrors (state) {
      state.errors = []
    },
    updateErrors (state, error) {
      state.errors.push(error)
    },
    showErrors (state, data) {
      state.errorsShow = data
    },
    excludeError (state, error) {
      let errors = []
      state.errors.forEach((x) => {
        if (Object.keys(x)[0] !== error) {
          errors.push(x)
        }
      })
      state.errors = errors
    },
    updateConfigs (state, data) {
      if (data.host) {
        state.configs.host = data.host
      } else {
        state.configs.host = window.location.href
      }

      if (data.selector) {
        state.configs.selector = data.selector
      }

      if (data.partner) {
        state.configs.partner.id = data.partner
      }

      if (data.ga) {
        state.configs.ga = data.ga
      }

      if (data.ym) {
        state.configs.ym = data.ym
      }

      if (data.mlayer) {
        state.configs.mlayer = data.mlayer
      }

      if ([ 'ru', 'en' ].includes(data.language)) {
        state.configs.language = data.language
      }

      if (data.theme_input) { state.configs.theme.input = data.theme_input }
      if (data.theme_inputErrorBackground) { state.configs.theme.inputErrorBackground = data.theme_inputErrorBackground }
      if (data.theme_inputFocus) { state.configs.theme.inputFocus = data.theme_inputFocus }
      if (data.theme_inputError) { state.configs.theme.ininputErrorput = data.theme_inputError }
      if (data.theme_primary) { state.configs.theme.primary = data.theme_primary }
      if (data.theme_secondary) { state.configs.theme.secondary = data.theme_secondary }
      if (data.theme_button_radius) { state.configs.theme.button_radius = data.theme_button_radius }
      if (data.theme_groupBackground) { state.configs.theme.groupBackground = data.theme_groupBackground }

      document.documentElement.style.setProperty('--c-input', state.configs.theme.input)
      document.documentElement.style.setProperty('--c-input-disabled', state.configs.theme.input)
      document.documentElement.style.setProperty('--c-input-focus', state.configs.theme.inputFocus)
      document.documentElement.style.setProperty('--c-input-error', state.configs.theme.inputError)
      document.documentElement.style.setProperty('--c-input-error-background', state.configs.theme.inputErrorBackground)
      document.documentElement.style.setProperty('--c-button', state.configs.theme.primary)
      document.documentElement.style.setProperty('--c-button-hover', state.configs.theme.secondary)
      document.documentElement.style.setProperty('--c-button-radius', state.configs.theme.button_radius)
      document.documentElement.style.setProperty('--c-group-background', state.configs.theme.groupBackground)
    }
  },

  getters: {
    getError: (state) => (input) => {
      let error = []
      if (state.errors) {
        state.errors.forEach((x) => {
          Object.keys(x).forEach((n) => {
            if (input === n) {
              error = x[n]
            }
          })
        })
      }
      return error
    },
    getFirstError: (state) => () => {
      return state.errors[0]
    },
    getSettingsElement: (state) => (element) => {
      return state.configs.elementsSettings[element] || {}
    },
    getApiUrl: (state) => () => {
      let apiUrl = state.configs.apiUrl.replace('http://', '').replace('https://', '')
      return (window.location.toString()).includes('https') ? 'https://' + apiUrl : 'http://' + apiUrl
    },
    getParseSchemeElements: (state) => () => {
      let scheme = []
      state.configs.schemeElements.forEach((x) => {
        if (typeof x === 'object') {
          x.forEach((n) => {
            if (n.indexOf('||') !== -1) {
              n.split('||').forEach((m) => {
                if (m.indexOf('form') === -1) {
                  scheme.push(m)
                }
              })
            } else {
              if (n.indexOf('form') === -1) {
                scheme.push(n)
              }
            }
          })
        } else if (typeof x === 'string') {
          if (x.indexOf('form') === -1) {
            scheme.push(x)
          }
        }
      })
      return scheme
    }
  }
})
